import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button, IconButton, CardMedia, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import { db } from '../firebase/firebaseConfig';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from './Sidebar';
import CircularProgress from '@mui/material/CircularProgress';


const EventDashboard = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchEvents(currentUser.uid);
      } else {
        console.log('No user logged in');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchEvents = async (userId) => {
    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      eventsList.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));
      setEvents(eventsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      setEvents(events.filter((event) => event.id !== id));
      console.log('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const upcomingEvents = events.filter((event) => dayjs(event.eventDate).isAfter(dayjs()));
  const completedEvents = events.filter((event) => dayjs(event.eventDate).isBefore(dayjs()));

  if (loading) {
   return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f4f8',
        }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar />
      <Container
        sx={{
          padding: '20px',
          background: 'linear-gradient(to bottom, #f0f4f8, #ffffff)',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          marginTop: '20px',
          marginBottom: '20px',
          width: 'calc(100% - 240px)',
          flexGrow: 1,
        }}
      >
        {upcomingEvents.length === 0 && completedEvents.length === 0 && (
          <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            Welcome to Your{' '}
            <Box
              component="span"
              sx={{
                color: '#2A73E8', // Blue color for "Event Dashboard"
              }}
            >
              Event Dashboard
            </Box>
            !
          </Typography>
          <Typography
  variant="h6"
  sx={{
    marginBottom: '30px',
  }}
>
  <span style={{ color: '#000000' }}>Manage your events effortlessly, collect photos, and share memories with your clients. Get started by</span>{' '}
  <span
    style={{
      color: '#FFD700', // Gold text
      fontWeight: 'bold',
      textDecoration: 'underline',
      textUnderlineOffset: '4px', // Better spacing for underline
    }}
  >
     creating your first event.
  </span>
</Typography>


          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            component={Link}
            to="/create-event"
            sx={{
              background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Blue gradient
              color: '#FFFFFF',
              fontWeight: 'bold',
              padding: '12px 24px',
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              '&:hover': {
                background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Enhanced hover shadow
              },
            }}
          >
            Create New Event
          </Button>
        </Box>
        
        )}

        {(upcomingEvents.length > 0 || completedEvents.length > 0) && (
          <>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
              Event Dashboard
            </Typography>
            <Button
  variant="contained"
  startIcon={<AddCircleOutline />}
  component={Link}
  to="/create-event"
  sx={{
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Premium gradient
    color: '#FFFFFF',
    fontWeight: 'bold',
    padding: '12px 24px',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    '&:hover': {
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker gradient on hover
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Enhanced shadow on hover
    },
  }}
>
  Create New Event
</Button>

          </>
        )}

        <Typography variant="h5" component="h2" sx={{ fontWeight: '600', marginBottom: '10px', textAlign: 'center' }}>
          Upcoming Events
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            overflowX: 'auto',
            padding: '10px 0',
            scrollBehavior: 'smooth',
          }}
        >
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event) => (
              <Card
                key={event.id}
                sx={{
                  width: '350px',
                  height: '500px',
                  flexShrink: 0,
                  borderRadius: '16px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
                }}
              >
                <CardMedia
                  component="img"
                  height="240"
                  image={event.coverPhotoUrl}
                  alt={event.eventName}
                  sx={{
                    borderRadius: '16px 16px 0 0',
                    objectFit: 'cover',
                    objectPosition: '50% 30%',
                  }}
                />
                <CardContent sx={{ padding: '24px' }}>
                  <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
                    {event.eventName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                    {dayjs(event.eventDate).format('MMMM D, YYYY')}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '16px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => navigate(`/events/${event.uniqueUrl}`)}
                    sx={{ textTransform: 'none', fontSize: '14px', color: '#1e88e5', fontWeight: 'bold' }}
                  >
                    View Details
                  </Button>
                  <IconButton onClick={() => handleDelete(event.id)} sx={{ color: '#d32f2f' }}>
                    <Delete fontSize="small" />
                  </IconButton>
                </CardActions>
              </Card>
            ))
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                textAlign: 'center',
              }}
            >
             Your events will appear here.
            </Typography>
          )}
        </Box>

        {completedEvents.length > 0 && (
          <>
            <Typography variant="h5" component="h2" sx={{ fontWeight: '600', marginTop: '40px', marginBottom: '10px' }}>
              Completed Events
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                overflowX: 'auto',
                padding: '10px 0',
                scrollBehavior: 'smooth',
              }}
            >
              {completedEvents.map((event) => (
                <Card
                  key={event.id}
                  sx={{
                    width: '350px',
                    height: '500px',
                    flexShrink: 0,
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="240"
                    image={event.coverPhotoUrl}
                    alt={event.eventName}
                    sx={{
                      borderRadius: '16px 16px 0 0',
                      objectFit: 'cover',
                      objectPosition: '50% 30%',
                    }}
                  />
                  <CardContent sx={{ padding: '24px' }}>
                    <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
                      {event.eventName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                      {dayjs(event.eventDate).format('MMMM D, YYYY')}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      paddingLeft: '24px',
                      paddingRight: '24px',
                      paddingBottom: '16px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() => navigate(`/events/${event.uniqueUrl}`)}
                      sx={{ textTransform: 'none', fontSize: '14px', color: '#1e88e5', fontWeight: 'bold' }}
                    >
                      View Details
                    </Button>
                    <IconButton onClick={() => handleDelete(event.id)} sx={{ color: '#d32f2f' }}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
            </Box>
          </>
        )}
      </Container>
    </div>
  );
};

export default EventDashboard;
