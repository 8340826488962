import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Container, TextField, Button, Typography, Box, Paper, Grid, Tooltip, IconButton } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firebaseConfig'; // Import your Firestore configuration
import InfoIcon from '@mui/icons-material/Info';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // After successful signup, initialize the user's document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        eventCount: 0, // Initialize event count to 0
        stripeId: '', // Optional: Set Stripe ID here if needed or add it later when linked to a subscription
        subscriptionStatus: 'free_trial', // Optional: Track subscription status if needed
      });

      // After successful signup, navigate to the subscription page
      navigate(`/subpage`);
    } catch (error) {
      console.error('Signup failed:', error);
      alert('Error signing up: ' + error.message);
    }
  };

  return (
    <Container
    maxWidth="sm"
    sx={{
      marginTop: { xs: '80px', md: '100px' }, // Push content below AppBar
      paddingBottom: '20px', // Add spacing at the bottom
    }}
  >
  
      <Paper elevation={3} style={{ padding: '20px', marginTop: '50px' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Sign Up
          </Typography>
          <form onSubmit={handleSignUp} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Enter a valid email address" arrow>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Password should be at least 8 characters long and include a number and a special character" arrow>
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
              <Button 
  type="submit" 
  variant="contained" 
  fullWidth
  sx={{ 
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Gradient effect for a premium feel
    color: '#fff', 
    fontWeight: 'bold', 
    fontSize: '16px', 
    textTransform: 'uppercase', // Gives a strong, professional appearance
    padding: '12px 16px', 
    borderRadius: '8px', // Smooth rounded edges for a modern touch
    '&:hover': { 
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Adds a subtle shadow effect on hover
    },
  }}
>
  Sign Up
</Button>




              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  By proceeding, you are agreeing to the{' '}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </Link>.
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUp;
