import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box, CircularProgress } from '@mui/material';
import { auth } from '../firebase/firebaseConfig';
import { getApp } from 'firebase/app';
import { getFirestore, doc, addDoc, collection, onSnapshot } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { CheckCircle } from '@mui/icons-material';

const plans = [
  { 
    name: 'Essential Plan', // Changed from Starter Plan
    price: '$295.00/year', 
    description: 'Up to 15 events, 14 day free trial', 
    features: [
      'Up to 15 events',
      'Each event has 3-month access',
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Online gallery customization',
      'Seamless photo sharing via QR code'
    ],
    priceId: 'price_1QKlDjRtiroMBcDIwgggoFGO',
    isFreeTrial: true,
  },
  { 
    name: 'Advanced Plan', // Changed from Pro Plan
    price: '$445.00/year', 
    description: 'Up to 30 events, 14 day free trial', 
    features: [
      'Up to 30 events',
      'Each event has 3-month access',
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Online gallery customization',
      'Seamless photo sharing via QR code'
    ],
    priceId: 'price_1QKlF1RtiroMBcDIcu7Z5SLz',
    isFreeTrial: true, // Missing comma added here
  },
  {
    name: 'Platinum Plan',
    price: '$699.00/year',
    description: 'Up to 60 events, 14 day free trial',
    features: [
      'Up to 60 events', // Updated this line
      'Each event has 3-month access',
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Online gallery customization',
      'Seamless photo sharing via QR code'
    ],
    priceId: 'price_1QQsgMRtiroMBcDIolQiYQub',
    isFreeTrial: true, // Missing comma added here

  },
];


const Subpage = () => {
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleCheckout = async (priceId) => {
    setLoading(true);
    setError(null);
  
    try {
      if (!userId) {
        throw new Error('User is not authenticated');
      }
  
      const app = getApp();
      const db = getFirestore(app);
  
      const userDocRef = doc(db, 'users', userId);
  
      const sessionData = {
        price: priceId,
        success_url: 'https://elevateevent.co/eventdashboard', // Updated success URL
        cancel_url: window.location.origin + '/cancel',
        allow_promotion_codes: true, // This enables the promo code field
      };
  
      // Add free trial days for specific plans
      if (priceId === 'price_1QKlDjRtiroMBcDIwgggoFGO') {
        sessionData.trial_period_days = 14; // Trial for Essential Plan
      }
      if (priceId === 'price_1QKlF1RtiroMBcDIcu7Z5SLz') {
        sessionData.trial_period_days = 14; // Trial for Advanced Plan
      }
      if (priceId === 'price_1QQsgMRtiroMBcDIolQiYQub') {
        sessionData.trial_period_days = 14; // Trial for Platinum Plan
      }
  
      const sessionRef = await addDoc(collection(userDocRef, 'checkout_sessions'), sessionData);
  
      const unsubscribe = onSnapshot(sessionRef, (doc) => {
        const data = doc.data();
        const stripeSessionId = data?.sessionId;
  
        if (stripeSessionId) {
          loadStripe('pk_live_51PYc2DRtiroMBcDILn1UgHqdZwqK8DWTpioBHvfAGvQOHrQ0B8f9v4J9aXpUcsLyqkKvQwiPbPo6N2bZTdR549Zg00Le2nYE3R')
            .then((stripe) => {
              if (stripe) {
                stripe.redirectToCheckout({ sessionId: stripeSessionId })
                  .then((result) => {
                    if (result.error) {
                      setError(result.error.message);
                    }
                  })
                  .catch((error) => {
                    setError(error.message);
                  })
                  .finally(() => setLoading(false));
              }
            })
            .catch((error) => {
              setError(error.message);
            });
        }
      });
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  
  return (
    <Container
    maxWidth="lg"
    sx={{
      marginTop: { xs: '80px', md: '100px' }, // Push content below the AppBar
      padding: { xs: 2, md: 4 },
      paddingBottom: '50px', // Extra padding for the bottom
      minHeight: 'calc(100vh - 100px)', // Full height minus AppBar height
      display: 'flex',
      flexDirection: 'column',
    }}
  >
  
      <Typography 
        variant="h4" 
        component="h1" 
        align="center" 
        gutterBottom 
        sx={{ color: '#000', fontWeight: 'bold' }}  // Black for main heading
      >
        Choose Your Plan
      </Typography>
      <Typography 
        variant="h6" 
        align="center" 
        color="textSecondary" 
        gutterBottom 
        sx={{ color: '#666', fontSize: { xs: '0.9rem', md: '1.25rem' } }} // Lighter gray for subheading
      >
        Tailored for event planners – collect and share photos seamlessly with no attendee login required.
      </Typography>
      
      <Grid container spacing={3} alignItems="stretch" justifyContent="center">
        {plans.map((plan, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            key={index}
          >
<Card 
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    backgroundColor: '#fff', // White for all plans
    color: '#000', // Black text for all plans
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    border: 'none', // No border for any plan
    position: 'relative',
    minHeight: '100%', 
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
    },
  }}
>
  <CardContent>
    <Typography variant="h5" sx={{ 
        fontWeight: 'bold', 
        fontSize: '1.75rem', // Increased font size for title 
        marginBottom: '8px' // Add a bit of space below the title 
    }}>
      {plan.name}
    </Typography>
    <Typography variant="h6" sx={{ 
        marginBottom: '16px', 
        fontSize: '1.25rem', // Increased font size for the price 
        fontWeight: '500' // Medium weight to balance visibility 
    }}>
      {plan.price}
    </Typography>
    <Typography variant="body1" sx={{ 
        marginBottom: '20px', 
        fontSize: '1.1rem', // Slightly larger for description 
        color: '#555' // A subtle color for a more professional look
    }}>
      {plan.description}
    </Typography>
    <Box component="ul" sx={{ padding: 0, listStyle: 'none' }}>
  {plan.features.map((feature, idx) => (
    <Box 
      component="li" 
      key={idx} 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '10px' 
      }}
    >
      <CheckCircle 
        sx={{ 
          color: '#2A73E8', // Blue checkmark color
          marginRight: '8px' 
        }} 
      />
      <Typography 
        variant="body2" 
        sx={{ 
          fontSize: '1.05rem', 
          color: '#333' 
        }}
      >
        {feature}
      </Typography>
    </Box>
  ))}
</Box>

  </CardContent>
  <Box textAlign="center" mt={2}>
  <Button
  variant="contained"
  sx={{
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Gradient effect for a premium feel
    color: '#fff', 
    padding: '14px 28px',
    fontWeight: 'bold',
    borderRadius: '8px',
    fontSize: '1rem',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Adds a subtle shadow effect on hover
    },
  }}
  onClick={() => handleCheckout(plan.priceId)}
  disabled={!userId}
>
  {plan.isFreeTrial ? 'Start Trial' : 'Select'}
</Button>


  </Box>
</Card>
          </Grid>
        ))}
      </Grid>

      {loading && (
        <Box textAlign="center" mt={4}>
          <CircularProgress />
          <Typography variant="h6" align="center" gutterBottom>
            Redirecting to checkout...
          </Typography>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Typography variant="h6" align="center" color="error">
            An error occurred: {error}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Subpage;
