import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Box,
} from '@mui/material';
import { Home, QrCode, Description } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logo from '../assets/logo.svg';

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hasSubscriptions, setHasSubscriptions] = useState(false); // Tracks user subscription state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  // Explicitly define paths where the sidebar should not show
  const hiddenPaths = ['/subpage', '/', '/signin', '/signup'];

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          setHasSubscriptions(false);
          return;
        }

        const db = getFirestore();
        const subscriptionsRef = collection(db, `users/${user.uid}/subscriptions`);
        const snapshot = await getDocs(subscriptionsRef);

        setHasSubscriptions(!snapshot.empty); // Set to true if user has active subscriptions
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setHasSubscriptions(false);
      }
    };

    fetchSubscriptions();
  }, []);

  // Hide Sidebar on specific paths or if no subscription exists
  if (hiddenPaths.includes(location.pathname) || !hasSubscriptions) {
    return null;
  }

  const drawerContent = (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        paddingTop: '40px', // Increased padding for spacing
        background: 'linear-gradient(to bottom, #E6E7E8, #D6D7D8)', // Slightly darker grey gradient
        color: '#6c757d', // Neutral gray for icons
      }}
    >
      {/* Logo */}
      <Box
        component="img"
        src={logo}
        alt="Logo"
        sx={{
          width: '50px', // Larger logo size
          height: '50px',
          marginBottom: '30px', // Adjust spacing
        }}
      />

      {/* Home */}
      <Tooltip title="Home" placement="right" arrow>
        <ListItem
          button
          component={Link}
          to="/eventdashboard"
          sx={{
            justifyContent: 'center',
            marginBottom: '20px', // Spacing between items
            '&:hover': {
              backgroundColor: '#E0E0E0', // Light grey hover effect
              borderRadius: '8px', // Rounded corners on hover
            },
          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
            }}
          >
            <Home
              sx={{
                fontSize: '40px', // Larger font size for the icon
                color: location.pathname === '/eventdashboard' ? '#007bff' : '#5a5a5a', // Blue for active, gray for inactive
              }}
            />
          </ListItemIcon>
        </ListItem>
      </Tooltip>

      {/* QR Code Templates */}
      <Tooltip title="QR Code Templates" placement="right" arrow>
        <ListItem
          button
          component={Link}
          to="/qrcode-templates"
          sx={{
            justifyContent: 'center',
            marginBottom: '20px', // Add spacing between icons
            '&:hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)',
              borderRadius: '8px',
            },
          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
            }}
          >
            <QrCode
              sx={{
                fontSize: '40px', // Larger font size for the icon
                color: location.pathname === '/qrcode-templates' ? '#007bff' : '#5a5a5a',
              }}
            />
          </ListItemIcon>
        </ListItem>
      </Tooltip>

      {/* Docs */}
      <Tooltip title="Docs" placement="right" arrow>
        <ListItem
          button
          component={Link}
          to="/docs"
          sx={{
            justifyContent: 'center',
            marginBottom: '40px', // Add spacing between icons
            '&:hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)',
              borderRadius: '8px',
            },
          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
            }}
          >
            <Description
              sx={{
                fontSize: '40px', // Larger font size for the icon
                color: location.pathname === '/docs' ? '#007bff' : '#5a5a5a',
              }}
            />
          </ListItemIcon>
        </ListItem>
      </Tooltip>
    </List>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': {
              width: 60,
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': {
              width: 80, // Increase width from 60 to 80
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
