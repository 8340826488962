// src/components/ChipList.js
import React, { useState } from 'react';
import { Container, Typography, Chip, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import styled from 'styled-components';
import ProductImage from '../assets/Step2.jpg';
import GalleryImage from '../assets/Gallery.jpg';
import DashboardImage from '../assets/Dashboard.jpg';
import QRCodeImage from '../assets/QRCode.jpg';

// Custom styled component for the main container with adjusted margins, black background, and white text
const FullHeightContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  overflow-x: hidden;
  background-color: #000; // Black background
  color: #fff;

  @media (max-width: 600px) {
    padding: 0 15px;
  }
`;

// Custom styled component for the ChipList container
const ChipContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  overflow-x: auto;
  padding: 10px 0;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d4af37;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

// Custom styled component for individual chips with hover and active states
const StyledChip = styled(Chip)`
  margin: 0 10px !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  font-weight: bold !important;
  padding: 8px 16px !important;
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.3s ease-in-out !important;

  @media (min-width: 960px) {
    font-size: 22px !important;
    padding: 16px 24px !important;
    min-height: 48px !important;
    border-radius: 28px !important;
  }

  &.MuiChip-clickable:hover {
    background-color: #000 !important;
    color: #fff !important;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3) !important;
  }

  &.MuiChip-clickable.MuiChip-colorPrimary {
    background-color: #000 !important;
    color: #fff !important;
  }
`;

const ChipList = () => {
  const [selectedChip, setSelectedChip] = useState('Customize');
  const chipLabels = ['Customize', 'Online Gallery', 'Dashboard', 'QR Code', 'Increase ROI'];

  const handleChipClick = (label) => {
    setSelectedChip(label);
  };

  const renderContent = () => {
    switch (selectedChip) {
      case 'Customize':
        return (
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card>
                <CardMedia
                  component="img"
                  image={ProductImage}
                  alt="Customize"
                  sx={{
                    height: 400,
                    objectFit: 'contain',
                  }}
                />
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Customize Your Events</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Personalize your event with custom titles, cover photos, background colors, and welcome messages.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case 'Online Gallery':
        return (
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card>
                <CardMedia
                  component="img"
                  image={GalleryImage}
                  alt="Online Gallery"
                  sx={{
                    height: 400,
                    objectFit: 'contain',
                  }}
                />
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Create Online Galleries</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Build beautiful online galleries to share with your clients and guests. Easily collect and display event photos and videos.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case 'Dashboard':
        return (
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card>
                <CardMedia
                  component="img"
                  image={DashboardImage}
                  alt="Dashboard"
                  sx={{
                    height: 400,
                    objectFit: 'contain',
                  }}
                />
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Comprehensive Dashboard</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Get real-time insights and data about your events, media uploads, and guest engagement through a user-friendly dashboard.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case 'QR Code':
        return (
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card>
                <CardMedia
                  component="img"
                  image={QRCodeImage}
                  alt="QR Code"
                  sx={{
                    height: 400,
                    objectFit: 'contain',
                  }}
                />
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Easy QR Code Integration</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Seamlessly generate and use QR codes for your events to enable quick and easy media uploads from guests.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case 'Increase ROI':
        return (
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Increase ROI</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Offer a premium service to your clients and increase your revenue by providing a more professional and customized experience.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      default:
        return <Typography>Select a chip to view more information.</Typography>;
    }
  };

  return (
    <FullHeightContainer>
      <Box sx={{ textAlign: 'center', marginBottom: '10px' }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#fff',
            fontFamily: 'Didot, serif',
            fontSize: '3rem',
          }}
        >
          Curated For Event Professionals
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
        <Typography variant="subtitle1" sx={{ color: '#fff' }}>
          Stand Out from the Competition
        </Typography>
      </Box>

      <ChipContainer>
        {chipLabels.map((label) => (
          <StyledChip
            key={label}
            label={label}
            color={selectedChip === label ? 'primary' : 'default'}
            onClick={() => handleChipClick(label)}
          />
        ))}
      </ChipContainer>

      <Box sx={{ marginTop: '20px', width: '100%', maxWidth: '800px' }}>{renderContent()}</Box>
    </FullHeightContainer>
  );
};

export default ChipList;
