import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/firebaseConfig';

// Define event limits based on priceId
const eventLimits = {
    'price_1QKlDjRtiroMBcDIwgggoFGO': 15,  // Essential Plan (formerly Starter Plan)
    'price_1QKlF1RtiroMBcDIcu7Z5SLz': 30   // Advanced Plan (formerly Pro Plan)
};

// Hardcoded price IDs for both Essential and Advanced plans
const essentialPlanId = "price_1QKlDjRtiroMBcDIwgggoFGO";
const advancedPlanId = "price_1QKlF1RtiroMBcDIcu7Z5SLz";

// Function to fetch the checkout session for the user based on priceId
const getCheckoutSessionByPrice = async (priceId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("User is not authenticated.");
      return null;
    }

    const userId = user.uid;
    const sessionsRef = collection(db, `users/${userId}/checkout_sessions`);

    // Create a query to find the document with the specific price
    const q = query(sessionsRef, where("price", "==", priceId));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let sessionData = null;
      querySnapshot.forEach((doc) => {
        sessionData = doc.data(); // Return the first matching document's data
      });
      return sessionData;
    } else {
      console.log("No matching documents found.");
      return null;
    }
  } catch (error) {
    console.error("Error getting documents: ", error);
    return null;
  }
};

// Function to check if the user can create more events
export const canUserCreateEvent = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    alert('User is not authenticated.');
    return false;
  }

  const userId = user.uid;
  const userRef = doc(db, 'users', userId);

  try {
    // Fetch the active subscription for either plan
    const checkoutSession = await getCheckoutSessionByPrice(essentialPlanId) || await getCheckoutSessionByPrice(advancedPlanId);

    if (!checkoutSession) {
      alert('No active subscription found.');
      return false;
    }

    const priceId = checkoutSession.price; // Get the priceId from the checkout session

    // Set the event limit based on the priceId
    let eventLimit = eventLimits[essentialPlanId]; // Default event limit
    if (priceId === essentialPlanId) {
      eventLimit = eventLimits[essentialPlanId]; // Event limit for Essential Plan
    } else if (priceId === advancedPlanId) {
      eventLimit = eventLimits[advancedPlanId]; // Event limit for Advanced Plan (7 events)
    } else {
      alert('Invalid subscription plan.');
      return false; // Exit if no valid subscription plan
    }

    // Fetch current event count
    const userDoc = await getDoc(userRef);
    const currentEventCount = userDoc.data().eventCount || 0;

    // Check if the user has reached the event limit
    if (currentEventCount >= eventLimit) {
      alert('Event creation limit reached for your plan.');
      return false;
    }

    return true;  // User can create an event

  } catch (error) {
    console.error('Error checking event limit:', error);
    return false;
  }
};

// Function to handle event creation
export const createEvent = async (eventData) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    console.error("User not authenticated");
    return;
  }

  const userId = user.uid;
  const userRef = doc(db, 'users', userId);

  // Fetch current event count
  const userDoc = await getDoc(userRef);
  const currentEventCount = userDoc.data().eventCount || 0;

  try {
    // Save form data to Firestore, including the userId (UID)
    const eventRef = doc(db, 'events', eventData.uniqueUrl);
    await setDoc(eventRef, eventData);

    // Increment the event count for the user
    await updateDoc(userRef, { eventCount: currentEventCount + 1 });

    console.log('Event created successfully');
  } catch (error) {
    console.error('Error creating event:', error);
  }
};
