import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Benefits = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#000', // Black background outside the card
        padding: '40px 0', // Add spacing for the container
      }}
    >
      <Container>
        <Box
          sx={{
            backgroundColor: '#E0E0E0', // Darker grey background for the card
            padding: '40px',
            borderRadius: '8px',
            textAlign: 'left',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Made for Event Professionals
          </Typography>
          <Typography paragraph>
            Elevate Event Studio empowers you to create unforgettable moments, leaving a lasting impression on your clients and their guests. Designed exclusively for event professionals, we take the stress out of media collection so you can focus on what you do best — creating magic.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Transform your events into unforgettable experiences that your clients will rave about." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Stand out as the go-to professional with innovative, cutting-edge services." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Bring joy to your clients by exceeding their expectations and making their lives easier." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Save countless hours with effortless media collection — no logins, no hassle, just results." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Boost your reputation and maximize revenue with professional, seamless photo sharing." />
            </ListItem>
          </List>
        </Box>
      </Container>
    </Box>
  );
};

export default Benefits;
