// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Box,   
  Container, 
  Grid 
} from '@mui/material';
import styled, { keyframes, css } from 'styled-components';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ImageIcon from '@mui/icons-material/Image';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import BarChartIcon from '@mui/icons-material/BarChart';
import FeatureCard from '../components/FeatureCard';
import FAQ from '../components/FAQ';
import Benefits from '../components/Benefits';
import ChipList from '../components/ChipList';
import ROICalculator from '../components/ROICalculator';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


import heroImage from '../assets/hero.jpg';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: -1; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const HeroContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0vh;
  padding-bottom: 5vh;
  background-color: black; /* Black background */
  color: white; /* White text */

  @media (max-width: 960px) { /* Apply default styles for smaller screens */
    background-color: transparent;
    color: black;
  }
`;


const QRCodeIcon = styled(QrCode2Icon)`
  font-size: 70px;
  color: #4D4D4D;
  margin-bottom: 20px;
`;

const moveUpAndDown = keyframes`
  0% { transform: translateY(10px); }
  40% { transform: translateY(-60px); }
  60% { transform: translateY(-60px); }
  100% { transform: translateY(10px); }
`;

const PhoneIcon = styled(PhoneIphoneIcon)`
  font-size: 80px;
  color: #333;
  margin-top: 10px;
  animation: ${css`${moveUpAndDown}`} 3s ease-in-out infinite;
`;

const scatterAndConverge = keyframes`
  0% { opacity: 0; transform: translate(0, 0) scale(0.5); }
  25% { opacity: 1; transform: translate(-50px, -40px) scale(1); }
  50% { transform: translate(60px, -40px) scale(1); }
  75% { transform: translate(10px, 30px) scale(1); }
  95% { transform: translate(15px, 15px) scale(0.1); opacity: 1; }
  100% { opacity: 0; transform: translate(0, 0) scale(0.5); }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

const FloatingImageIcon = styled(ImageIcon)`
  position: absolute;
  font-size: 35px;
  color: #D3B58A;
  opacity: 0;
  animation: ${css`${scatterAndConverge}`} 3s ease-in-out infinite;
  border: 1px solid #D4AF37;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  &:nth-child(1) { animation-delay: 0.5s; }
  &:nth-child(2) { animation-delay: 0.7s; }
  &:nth-child(3) { animation-delay: 0.9s; }
  &:nth-child(4) { animation-delay: 1.1s; }
`;

const EnhancedButton = styled(Button)`
  background: linear-gradient(135deg, #FFD700 0%, #D4AF37 100%) !important;
  color: #333 !important;
  border-radius: 8px !important; /* More squared edges */
  padding: 10px 50px !important; /* Thinner padding */
  width: 300px !important; /* Longer width */
  font-size: 20px !important;
  font-weight: 500 !important;
  box-shadow: 0px 8px 20px rgba(212, 175, 55, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover {
    background: linear-gradient(135deg, #D4AF37 0%, #B8860B 100%) !important;
    box-shadow: 0px 10px 30px rgba(212, 175, 55, 0.4) !important;
  }

  @media (max-width: 768px) { /* Hides button on mobile */
    display: none !important;
  }
`;


const MobileGradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 1;
`;


const MobileHeroButton = styled(Button)`
  width: 100%;
  background: linear-gradient(135deg, #FFD700, #D4AF37) !important; /* Gradient button */
  color: #000 !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 10px 0 !important;
  box-shadow: 0px 8px 20px rgba(212, 175, 55, 0.3) !important;

  &:hover {
    background: linear-gradient(135deg, #D4AF37, #B8860B) !important;
  }
`;
const MobileHeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for better contrast */
  z-index: 2;

  @media (min-width: 960px) { /* Hides overlay for desktop */
    display: none;
  }
`;

const MobileHeroText = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  z-index: 3;
  padding: 0 20px; /* Adds padding for better readability */
  font-family: "Futura", sans-serif;

  @media (min-width: 960px) { /* Hides text for desktop */
    display: none;
  }
`;

const Home = () => {
  return (
    <Box sx={{ width: '100%', margin: 0, padding: 0, overflowX: 'hidden' }}>
      <Background />

      <Container maxWidth={false} disableGutters sx={{ px: 0 }}>
  
      <Grid container spacing={0} alignItems="stretch">
  {/* Hero Image Section */}
  <Grid
    item
    xs={12}
    md={6}
    sx={{
      position: 'relative',
      height: { xs: 'auto', md: '100vh' },
      overflow: 'hidden',
      order: { xs: 1, md: 2 }, // Image first on mobile, second on desktop
    }}
  >
    {/* Hero Image */}
    <Box
      component="img"
      src={heroImage}
      alt="Hero"
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: '60vh',
        objectFit: 'cover',
        objectPosition: { xs: 'center top', md: 'center' },
      }}
    />

    {/* Mobile-Only Dark Overlay */}
    <Box
      sx={{
        display: { xs: 'block', md: 'none' }, // Visible only on mobile
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black overlay
        zIndex: 2,
      }}
    />

    {/* Mobile-Only Text Overlay */}
    <Typography
      variant="h5"
      sx={{
        display: { xs: 'block', md: 'none' }, // Visible only on mobile
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: 700,
        zIndex: 3, // Above overlay
        fontFamily: `"Futura", sans-serif`,
        padding: '0 20px', // Adds padding for readability
        lineHeight: 1.4, // Improves text spacing
      }}
    >
      Effortlessly Create Live Event Galleries
      <br />
      <Typography
        component="span"
        sx={{
          fontWeight: '900', // Bold emphasis
          display: 'block',
        }}
      >
        with Custom QR Codes
      </Typography>
    </Typography>

    {/* Mobile-Only CTA Button */}
    <Button
      component={Link}
      to="/signup"
      sx={{
        display: { xs: 'block', md: 'none' }, // Visible only on mobile
        position: 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '1rem',
        fontWeight: 700,
        padding: '10px 20px',
        borderRadius: '6px',
        textTransform: 'none',
        zIndex: 4, // Above everything else
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      Start Now
    </Button>
  </Grid>

 {/* Left Section Content */}
<Grid
  item
  xs={12}
  md={6}
  sx={{
    display: { xs: 'none', md: 'flex' }, // Hide on mobile, visible only on desktop
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black', // Black background for the section
    color: 'white',
    padding: { xs: '20px', md: '40px' },
    height: { xs: 'auto', md: '100vh' },
    order: { xs: 2, md: 1 }, // Content appears second on mobile, first on desktop
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontSize: { xs: '1.2rem', md: '2.5rem' }, // Scaled font size
      fontFamily: `"Futura", sans-serif`,
      fontWeight: '700',
      letterSpacing: '0.1em',
      textAlign: 'center',
      marginBottom: '15px',
    }}
  >
    Effortlessly Create Live Event Galleries with Custom QR Codes
  </Typography>

  <QRCodeIcon sx={{ fontSize: '50px', marginBottom: '15px' }} />
  <PhoneIcon sx={{ fontSize: '60px', marginBottom: '20px' }} />

  <EnhancedButton component={Link} to="/signup" sx={{ marginTop: '20px' }}>
    Start Now
  </EnhancedButton>
</Grid>
</Grid>

 

<Grid container justifyContent="center" sx={{ mt: 6, mb: 4 }}>
  <Typography
    variant="h4"
    sx={{
      fontFamily: `"Futura", sans-serif`,
      fontWeight: 700,
      fontSize: '2.5rem',
      color: '#000',
      textAlign: 'center',
      mb: 3, // Add spacing below the title
    }}
  >
    How It Works
  </Typography>
</Grid>

<Grid container spacing={4} justifyContent="center">
  {/* Step 1 */}
  <Grid item xs={12} sm={6} md={4}>
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        border: '5px solid #000',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          width: 100,
          height: 100,
          backgroundColor: '#000',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          marginBottom: '20px',
        }}
      >
        <CalendarTodayIcon sx={{ fontSize: 50, color: '#D4AF37' }} />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: '600',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Step 1: Create Your Event
      </Typography>
      <Typography variant="body2" sx={{ color: '#555' }}>
        Easily set up your event and start customizing your gallery with just a few clicks.
      </Typography>
    </Box>
  </Grid>

  {/* Step 2 */}
  <Grid item xs={12} sm={6} md={4}>
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        border: '5px solid #000',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          width: 100,
          height: 100,
          backgroundColor: '#000',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          marginBottom: '20px',
        }}
      >
        <QrCode2Icon sx={{ fontSize: 50, color: '#D4AF37' }} />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: '600',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Step 2: Customize & Share
      </Typography>
      <Typography variant="body2" sx={{ color: '#555' }}>
        Make it unique and share a scannable QR code with attendees instantly.
      </Typography>
    </Box>
  </Grid>

  {/* Step 3 */}
  <Grid item xs={12} sm={6} md={4}>
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        border: '5px solid #000',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          width: 100,
          height: 100,
          backgroundColor: '#000',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          marginBottom: '20px',
        }}
      >
        <ImageIcon sx={{ fontSize: 50, color: '#D4AF37' }} />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: '600',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Step 3: Showcase at the Event
      </Typography>
      <Typography variant="body2" sx={{ color: '#555' }}>
        Place the poster on tables, and guests can upload photos and videos in seconds.
      </Typography>
    </Box>
  </Grid>
</Grid>

</Container>


{/* Keep this above ChipList */}
<Box sx={{ mt: 10 }}>
  <ChipList />
</Box>


      <Benefits />

      <Container maxWidth="lg" sx={{ mt: 8, textAlign: 'center' }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontFamily: `"Futura", sans-serif`, 
            color: '#000',
            fontSize: '2.5rem',
          }}
        >
          Ready to Transform Your Events?
        </Typography>
        <EnhancedButton component={Link} to="/signup">
          Start Now
        </EnhancedButton>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontFamily: `"Futura", sans-serif`, 
            color: '#000',
            fontSize: '2.5rem',
          }}
        >
          Frequently Asked Questions
        </Typography>
        <FAQ />
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 10 }}>
        <ROICalculator />
      </Container>

      <Box sx={{ textAlign: 'center', padding: '20px 0', backgroundColor: '#f0f0f0' }}>
        <Typography variant="body2" sx={{ fontFamily: `"Futura", sans-serif`, color: '#000', fontSize: '1.1rem' }}>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', color: '#000' }}>
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
