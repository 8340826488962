import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for CircularProgress
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      // Firebase Authentication
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Corrected subcollection name to `subscriptions`
      const db = getFirestore();
      const subscriptionsRef = collection(db, `users/${user.uid}/subscriptions`); // Updated here
      const snapshot = await getDocs(subscriptionsRef);
  
      if (!snapshot.empty) {
        // Subcollection exists, redirect to eventdashboard
        navigate('/eventdashboard');
      } else {
        // Subcollection doesn't exist, redirect to subpage
        navigate('/subpage');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      alert('Error signing in: ' + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: { xs: '80px', md: '100px' }, // Adds spacing to push the content below the AppBar
        paddingBottom: '20px', // Adds space at the bottom for better layout
      }}
    >
      <Paper elevation={3} style={{ padding: '20px', marginTop: '50px' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Sign In
          </Typography>
          <form onSubmit={handleSignIn} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Enter your registered email address" arrow>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Enter your password" arrow>
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
              <Button
  type="submit"
  variant="contained"
  fullWidth
  disabled={loading} // Disable button when loading
  sx={{
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Premium gradient
    color: '#fff',
    padding: '12px 16px', 
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
    borderRadius: '8px', // Smooth rounded edges
    '&:hover': {
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow on hover
    },
  }}
>
  {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Sign In'}
</Button>

              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignIn;
