import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';

const FeatureCard = ({ title, description, icon }) => {
  return (
    <Box
      sx={{
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%', // Ensures all cards have the same height
      }}
    >
      {/* Icon Container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          backgroundColor: '#f0f4ff',
          marginBottom: '20px',
        }}
      >
        {icon} {/* Icon passed as a prop */}
      </Box>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {title}
      </Typography>
      <Typography paragraph>{description}</Typography>
    </Box>
  );
};

// Internal usage of FeatureCard for the "How It Works" section
const steps = [
  {
    title: 'Create Your Event',
    description: 'Easily set up your event and start customizing your gallery with just a few clicks.',
    icon: <CalendarMonthIcon sx={{ fontSize: '40px', color: '#1a73e8' }} />,
  },
  {
    title: 'Customize & Share',
    description: 'Make it unique and share a scannable QR code with attendees instantly.',
    icon: <QrCodeIcon sx={{ fontSize: '40px', color: '#1a73e8' }} />,
  },
  {
    title: 'Showcase at the Event',
    description: 'Place the poster on tables, and guests can upload photos and videos in seconds.',
    icon: <TableRestaurantIcon sx={{ fontSize: '40px', color: '#1a73e8' }} />,
  },
];

const HowItWorks = () => (
  <Box
    sx={{
      backgroundColor: '#f9f9f9',
      padding: '40px',
      borderRadius: '8px',
      textAlign: 'center',
    }}
  >
    <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
      How It Works
    </Typography>
    <Grid container spacing={3}>
      {steps.map((step, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <FeatureCard title={step.title} description={step.description} icon={step.icon} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default FeatureCard;
