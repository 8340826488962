import React, { useState, useRef } from 'react';
import { Button, Container, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { canUserCreateEvent, createEvent } from './EventLimitations';
import { getAuth } from 'firebase/auth';

const EventPreviewForm = ({ eventDetails, customizationData }) => {
  const [qrCode, setQrCode] = useState('');
  const [eventUrl, setEventUrl] = useState('');
  const qrRef = useRef();

  const handleCreateEvent = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('User not authenticated');
      return;
    }

    const canCreate = await canUserCreateEvent();
    if (!canCreate) return;

    const shortUuid = uuidv4().replace(/-/g, '').slice(0, 12);
    const cleanedEventName = eventDetails.eventName
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]+/g, '');
    const fullPathUrl = `${cleanedEventName}-${shortUuid}`;
    const eventFullUrl = `${window.location.origin}/events/${fullPathUrl}`;

    const formData = {
      ...eventDetails,
      ...customizationData,
      uniqueUrl: fullPathUrl,
      userId: user.uid, // Ensure userId is included
      coverPhotoUrl: customizationData.coverPhotoUrl,
    };

    try {
      await createEvent(formData);
      setQrCode(eventFullUrl);
      setEventUrl(eventFullUrl);
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const downloadQRCodeAsImage = async () => {
    const canvas = await html2canvas(qrRef.current);
    const link = document.createElement('a');
    link.download = `${eventDetails.eventName}-QRCode.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  const downloadQRCodeAsPDF = async () => {
    const canvas = await html2canvas(qrRef.current);
    const pdf = new jsPDF();
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 10, 10);
    pdf.save(`${eventDetails.eventName}-QRCode.pdf`);
  };

  const shareEvent = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Event QR Code',
          text: 'Scan this QR code to access the event',
          url: eventUrl,
        })
        .catch((error) => console.error('Error sharing', error));
    } else {
      alert('Sharing not supported on this browser');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Event Preview
      </Typography>

      <Button variant="contained" color="primary" onClick={handleCreateEvent}>
        Create Event
      </Button>

      {qrCode && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Scan QR Code
          </Typography>
          <div ref={qrRef} style={{ display: 'inline-block' }}>
            <QRCode value={qrCode} />
          </div>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {eventUrl}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadQRCodeAsImage}
            style={{ margin: '10px' }}
          >
            Download as Image
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadQRCodeAsPDF}
            style={{ margin: '10px' }}
          >
            Download as PDF
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={shareEvent}
            style={{ margin: '10px' }}
          >
            Share Event
          </Button>
        </div>
      )}
    </Container>
  );
};

export default EventPreviewForm;
