import React from 'react';
import { Typography, Box, Card } from '@mui/material';

const templates = [
  {
    title: 'Pink and White Watercolor Birthday Party Invitation',
    embed: 'https://www.canva.com/design/DAGXKvihBhU/hc2KdQH7Bj0azZp4uMuehg/view?embed',
  },
  {
    title: 'Elegant Engagement Party Table Sign',
    embed: 'https://www.canva.com/design/DAGYXWuObk8/rqqdAKuQug9Rxy1o8d4n7w/view?embed',
  },
  {
    title: 'Aesthetic Quinceanera Table Sign',
    embed: 'https://www.canva.com/design/DAGYXgL2pso/jBAoPXHMx8BD44sAQPtr3A/view?embed',
  },
  {
    title: 'Corporate Christmas Party Table Sign',
    embed: 'https://www.canva.com/design/DAGYXEcWWxc/C1SdRVahQ0z7EZVzevJ6zQ/view?embed',
  },
];

const QRCodeTemplates = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#333' }}
      >
        Canva Template Ideas
      </Typography>
      <Typography
        variant="h6"
        component="h2"
        color="textSecondary"
        sx={{ marginBottom: '30px', textAlign: 'center' }}
      >
        Explore Canva templates for inspiration and design ideas for your events.
      </Typography>

      {/* Integration Coming Soon Banner */}
      <Box
        sx={{
          background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)',
          color: '#FFFFFF',
          padding: '12px 24px',
          borderRadius: '8px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '18px',
          marginBottom: '30px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        Integration Coming Soon – Seamlessly Customize Your Templates
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '20px',
          width: '100%',
        }}
      >
        {templates.map((template, index) => (
          <Card
            key={index}
            sx={{
              width: 300,
              borderRadius: '10px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              backgroundColor: '#fff',
            }}
          >
            <iframe
              src={template.embed}
              loading="lazy"
              style={{
                width: '100%',
                height: '300px',
                border: 'none',
              }}
            />
            <Box
              sx={{
                padding: '16px',
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#333' }}
              >
                {template.title}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default QRCodeTemplates;
