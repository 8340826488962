import React, { useState } from 'react';
import { TextField, Button, Container } from '@mui/material';

const EventDetailsForm = ({ onNext }) => {
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');

  const handleNext = () => {
    if (!eventName || !eventDate) {
      alert('Please provide event name and date.');
      return;
    }
    onNext({ eventName, eventDate, welcomeMessage });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {/* Event Name Input */}
      <TextField
        label="Event Name"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      {/* Event Date Input */}
      <TextField
        label="Event Date"
        type="date"
        value={eventDate}
        onChange={(e) => setEventDate(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        required
      />

      {/* Custom Greeting Input */}
      <TextField
        label="Custom Greeting"
        value={welcomeMessage}
        onChange={(e) => setWelcomeMessage(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        placeholder="Optional"
      />

      {/* Next Button */}
      <Button
  variant="contained"
  sx={{
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Blue gradient
    color: '#FFFFFF',
    fontWeight: 'bold',
    padding: '12px 24px',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    '&:hover': {
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Enhanced hover shadow
    },
  }}
  onClick={handleNext}
  fullWidth
>
  Next
</Button>

    </Container>
  );
};

export default EventDetailsForm;
